import styled, { css } from 'styled-components';

const BoldFont = "Rubik-Bold";

const EngagementTab = styled.div`
  height: auto;
  padding: 42px 37px 30px 32px;

  @media (max-width: 500px) {
    padding: 20px 10px;
  }

  .addbutton{
    cursor: pointer;
    display: inline-block;
    float: right;
    text-align: center;
    background-color: #159fc9;
    color: white;
    font-size: 14px;
    font-family: ${BoldFont};
    width: 20px;
    border-radius: 50%;
  }
`;

const EmployeeCard = styled.div`
  margin: 0px;
  background-color: white;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: ${({paddingTop}) => paddingTop ? '0px 10px 10px 10px': '10px'};

  .emp-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .top-name {
    font-size: 14px;
    margin-left: 5px;
    word-break: break-word;
  }
  .emp-title {
    width: 70%;
  }
  @media (max-width: 1200px) {
    .emp-title {
      width: 77%;
    }
  }
`;

const NewUserCard = styled(EmployeeCard)`
  padding: ${({padding}) => padding ? padding : '0px' };
  border-bottom: ${({borderTop}) => borderTop ? '' : '1px solid #ededed' };
  > svg{
    width: ${({width}) => width ? width: '5%'};
  }

  height: auto;
  .employee-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  ${({ height }) => height && css`
    height: 66px;
    @media (max-width: 1050px) and (min-width: 951px) {
      height: auto;
      min-height: 66px;
    }
  `}
`;

const EmployeeGrid = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '20%'};
  font-size: ${({textAlign}) => textAlign ? '30px': '12px' };
  text-align: ${({textAlign}) => textAlign ? 'right': 'left'};
  color: ${({color}) => color ? color : 'rgb(62,78,104)'};
  padding-top: ${({paddingTop}) => paddingTop ? '6px': '0px'};
  padding-left: ${({ paddingLeft }) => paddingLeft ? '5': '0px' };
  text-align: ${({ groupActivity }) => groupActivity && 'center'};
  display: ${({ groupActivity }) => groupActivity && 'flex'};
  justify-content: ${({ groupActivity }) => groupActivity && 'center'};
  align-items: ${({ groupActivity }) => groupActivity && 'center'};
  font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };

  ${({ employeeGrid }) => employeeGrid && css`
    font-weight: 300;
  `}

  @media (max-width: 1200px) {
    font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
  }

  > img {
    border-radius: ${({borderRadius}) =>  borderRadius ? '' : '50%'};
    width: ${({minWidth, initaitive}) =>  minWidth ?  initaitive ? '23px' : '30px' :'50px'};
    height: ${({minWidth, initaitive}) =>  minWidth ? initaitive ? '23px' : '30px' :'50px'};;
  }
  .company {
    font-weight: normal;
    text-transform: uppercase;
    color: rgb(153,153,153);
    }
  }
  > button {
    background-color: #26b0c1;
    color: white;
  }
  > svg {
    height: 25px;
    width: ${({width}) => width ? '40px': '25px'};
    @media(max-width: 500px) {
      width: ${({width}) => width ? '30px': '20px'};
    }
  }
  .fitnessDevice {
    font-family: ${BoldFont};
    font-size: 14px;
  }
  .deviceName {
    text-transform: capitalize;
    font-size: 16px;
  }
  .name {
    font-family: ${BoldFont};
    font-size: 17px;
    padding-left: 5px;
    color: #333333;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .user-name {
    font-family: ${BoldFont};
    font-size: 16px;
    color: #333333;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .values {
    font-size: 14px;
    font-family: ${BoldFont};
    @media(max-width: 500px) {
      font-size: 11px;
    }
  }
  
  .device-images {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
  
  ${({ isBigImage }) => isBigImage && css`
    .device-images {
      width: 42px;
      margin-right: 10px;
    }
  `}
`;

const EngagementContainer = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  margin-bottom: ${({marginBottom}) => marginBottom ? '15px': '0px'};
  margin-top: ${({margin}) => margin ? '-75px': '0px'};
  @media (max-width: 760px) { 
    display: block;
  }
`;

const SplitEngagementSection = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '32.9%'};
  padding-left: ${({PaddingRight}) => PaddingRight ? '25px': '0px'};
  float: left;
  display: ${({displayNone}) => displayNone && 'none'};
  @media (max-width: 1080px) {
    width: ${({ smallWidth }) => smallWidth && smallWidth };
  } 
  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 15px;
    padding:${({ smallPadding }) => smallPadding && smallPadding };
  }
  .boxShadow {
    box-shadow: 0 0 21px rgba(0,0,0,0.08);
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .groupActivity {
      border-bottom: 1px solid #ededed;
      height: 52px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: ${BoldFont};
      font-size: 14px;
      background-color: white;
      color: #333333;
      text-transform: uppercase;
    }
  }
`;

const CommonContainer = styled.div`
  width: 100%;
  height: ${({fitnessHeight}) => fitnessHeight ? '286px' : '100%'};
  // background-color:  ${({bgColor}) => bgColor ? bgColor : 'white'};
  padding: ${({padding}) => padding ? padding : '0px'};
  border-top: ${({ borderTop }) => borderTop ? '1px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};

  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
  .fitnessDeviceHeight {
    padding-top: 10px;
  }
  .highcharts-credits {
    display: none; 
  }

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    padding: 2px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  
  ${({ flex }) => flex && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    overflow: auto;
  `}
`;

const UserButton = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'};
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'};
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  // opacity: ${({disabled}) => disabled && '0.5'};
  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;

const UserButtonNew = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'};
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'};
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  // opacity: ${({disabled}) => disabled && '0.5'};
  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
    background:#005C87;
    color:#ffff;
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;

//Todo:- Replace the display: none to display: flex in button className
const ChampionsContainer = styled.div`
  border: 1px solid #e9e8e8;
  border-radius: 2px;
  text-align: center;
  height: 275px;
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 880px) {
    width: 80%;
    margin-left: 10%;
  }
  > div{
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        height: auto;
        width: auto;
        border-radius: 50%;
        border: 7px solid #e3e3e3;
        > img {
          height: 65px;
          width: 65px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .company {
        font-weight: normal;
        text-transform: capitalize;
        color: rgb(153,153,153);
        font-size: 12px;
      }
    }
    .button {
      margin-top: 20px;
      display: none;
      > span{
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }
  .name {
    font-family: ${BoldFont};
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 20px;
    color: #333333;
  }
`;
const NoData = styled.div`
  width: 100%;
  padding: 10px 0;
  text-align: center;
`;

const UserButtonV2 = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'};
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'};
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0px'};

  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;





const UserButtonUpdate = styled.button`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: nowrap;
  padding: ${({padding}) => padding ? padding : '4px 7px'};
  width: ${({width}) => width ? width: '100%'};
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'};
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '5px'};
  font-family:  ${({font}) => font ? font : `${BoldFont}`};
  font-weight: ${({font}) => font && '600'};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  border: ${({border}) => border ? '1px solid lightgray': 'none'};
  height: ${({ height }) => height ? height : '46px'};
  box-sizing:  ${({ boxSizing}) => boxSizing ? "border-box" : 'none'};
  // opacity: ${({disabled}) => disabled && '0.5'};
  img {
    height: 18px;
    width: 18px;
  }

  &:hover, &:active, &:focus{
    outline: ${({outline}) => outline ? '' : 'none'};
    background:#005C87;
    color:#ffff;
  }

  .send 
  {
text-transform: capitalize;   
font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
  }
`;


export { EngagementTab, EmployeeCard, EmployeeGrid, EngagementContainer, SplitEngagementSection,UserButtonUpdate, CommonContainer, UserButton, NewUserCard, ChampionsContainer, NoData, UserButtonV2, UserButtonNew}
